import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule, DEBUG_MODE } from '@angular/fire/analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';


const firebaseConfig = {
  apiKey: "AIzaSyAJEawywQ3Du-BtA-Y68iHfC64zuMDQ6ao",
  authDomain: "aeternum-creations.firebaseapp.com",
  projectId: "aeternum-creations",
  storageBucket: "aeternum-creations.appspot.com",
  messagingSenderId: "347185824596",
  appId: "1:347185824596:web:a726308b58c161f4373ec1",
  measurementId: "G-KC344N96YH"
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    ToastNoAnimationModule.forRoot({
      preventDuplicates: true,
    }),
  ],
  providers: [
    { provide: DEBUG_MODE, useValue: !environment.production },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
