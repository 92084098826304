import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
 
  {
    path: 'terms',
    redirectTo: '/policies/terms',
    pathMatch: 'full'
  },
  {
    path: 'collection/:id',
    redirectTo: '/shop/collection/:id',
    pathMatch: 'full'
  },
  { path: 'shop/collection/:id', loadChildren: () => import('./pages/shop/collection/collection.module').then(m => m.CollectionModule) },
  { path: 'shop/product/:id', loadChildren: () => import('./pages/shop/product/product.module').then(m => m.ProductModule) },
  { path: 'policies/terms', loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule) },
  { path: 'policies/returns', loadChildren: () => import('./pages/returns/returns.module').then(m => m.ReturnsModule) },
  { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule) },
  { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'checkout/complete', loadChildren: () => import('./pages/checkout/complete/complete.module').then(m => m.CompleteModule) },
  { path: 'checkout/success', loadChildren: () => import('./pages/checkout/success/success.module').then(m => m.SuccessModule) },
  { path: 'checkout/error', loadChildren: () => import('./pages/checkout/error/error.module').then(m => m.ErrorModule) },
  { path: 'order/:id', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule) },
  {
    path: 'promo/:promo-code',
    redirectTo: ''
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
